
// Fonts
// @import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
// @import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
// @import "node_modules/bulma/bulma";
@import url('https://fonts.googleapis.com/css?family=Denk+One');
@import 'node_modules/sweetalert2/dist/sweetalert2';

* {
	font-family: 'Denk One', sans-serif;
}
#homeSlider {
	margin-bottom: 25px;
}
.content {
	background-color: darken($body-bg, 10%)
}
.content p {
	font-size: 1.2em;
	padding-bottom: 10px;
}

.carousel-control {
	background-image: none !important;
}
#header, #footer {
	background: rgba(243, 226, 199, 1);
	background: -moz-linear-gradient(left, rgba(243, 226, 199, 1) 0%, rgba(193, 158, 103, 1) 32%, rgba(182, 141, 76, 1) 54%, rgba(194, 147, 78, 1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(243, 226, 199, 1)), color-stop(32%, rgba(193, 158, 103, 1)), color-stop(54%, rgba(182, 141, 76, 1)), color-stop(100%, rgba(194, 147, 78, 1)));
	background: -webkit-linear-gradient(left, rgba(243, 226, 199, 1) 0%, rgba(193, 158, 103, 1) 32%, rgba(182, 141, 76, 1) 54%, rgba(194, 147, 78, 1) 100%);
	background: -o-linear-gradient(left, rgba(243, 226, 199, 1) 0%, rgba(193, 158, 103, 1) 32%, rgba(182, 141, 76, 1) 54%, rgba(194, 147, 78, 1) 100%);
	background: -ms-linear-gradient(left, rgba(243, 226, 199, 1) 0%, rgba(193, 158, 103, 1) 32%, rgba(182, 141, 76, 1) 54%, rgba(194, 147, 78, 1) 100%);
	background: linear-gradient(to right, rgba(243, 226, 199, 1) 0%, rgba(193, 158, 103, 1) 32%, rgba(182, 141, 76, 1) 54%, rgba(194, 147, 78, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3e2c7', endColorstr='#c2934e', GradientType=1 );
	padding: 10px 0 10px 0;
}
.navbar .navbar-nav {
  display: inline-block;
  float: none;
  vertical-align: top;
}

.navbar .navbar-collapse {
  text-align: center;
}
#header .navbar {
	padding: 10px 0 5px 0;
	background-color: transparent !important;
}


.seriesImage {
	padding-bottom: 10px;
}

.nav li a {
	color: #281908;
	font-family: 'Denk One', sans-serif;
	font-size: 1.5em;
}


.sidebar img {
	padding-bottom: 5px;
}

.navbar {

}

div#aboutUs {
	padding: 0 10px 0 10px;
}

div#aboutUs img {
	padding: 10px;
}
div#aboutUs p {
	text-indent: 7px;
}
#map {
	width: 100%;
	height: 400px;
	background-color: grey;
}